<div class="sale-transaction-completed-container">
    <div class="sale-transaction-completed-wrapper">
      <h2>Your transaction has been broadcasted successfully!</h2>
      <button class="button button--primary w--100_important view-tx" type="button" (click)="openTxHash()">
        <span>View transaction</span>
      </button>
      <button class="button button--secondary_accent w--100_important" type="button" (click)="close()">
        <span>Close</span>
      </button>
    </div>
  </div>
  
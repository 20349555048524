import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faArrowRightLong, faCopy, faCheck } from '@fortawesome/free-solid-svg-icons';
import { faXTwitter, faReddit, faTelegram } from '@fortawesome/free-brands-svg-icons';

import { MatDialogModule } from '@angular/material/dialog';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './pages/home/home.component';
import { HeroComponent } from './components/hero/hero.component';
import { PointsInfoComponent } from './components/points-info/points-info.component';
import { GeneralInfoComponent } from './components/general-info/general-info.component';
import { LeagueInfoComponent } from './components/league-info/league-info.component';
import { MatchInfoComponent } from './components/match-info/match-info.component';
import { TeamInfoComponent } from './components/team-info/team-info.component';
import { SignUpComponent } from './components/sign-up/sign-up.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ChartComponent } from './components/chart/chart.component';
import { NgChartsModule } from 'ng2-charts';
import { TokenomicsComponent } from './components/tokenomics/tokenomics.component';
import { InputComponent } from './components/shared/input/input.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { TokenSaleHeroComponent } from './components/token-sale-hero/token-sale-hero.component';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { RoadmapComponent } from './components/roadmap/roadmap.component';
import { GradientOverlayComponent } from './components/shared/gradient-overlay/gradient-overlay.component';
import { RoadmapCirclesOverlayComponent } from './components/shared/roadmap-circles-overlay/roadmap-circles-overlay.component';
import { HeroOverlayComponent } from './components/shared/hero-overlay/hero-overlay.component';
import { DecimalPipe } from '@angular/common';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeroComponent,
    PointsInfoComponent,
    GeneralInfoComponent,
    LeagueInfoComponent,
    MatchInfoComponent,
    TeamInfoComponent,
    SignUpComponent,
    FooterComponent,
    HeaderComponent,
    ChartComponent,
    TokenomicsComponent,
    TokenSaleHeroComponent,
    InputComponent,
    RoadmapComponent,
    GradientOverlayComponent,
    RoadmapCirclesOverlayComponent,
    HeroOverlayComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FontAwesomeModule,
    BrowserAnimationsModule,
    NgChartsModule,
    FormsModule,
    ReactiveFormsModule,
    ClipboardModule,
    MatDialogModule
  ],
  providers: [
    provideAnimationsAsync(),
    DecimalPipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(library: FaIconLibrary) {
    // Add an icon to the library for convenient access in other components
    library.addIcons(faArrowRightLong, faCopy, faCheck, faXTwitter, faReddit, faTelegram);
  }
}

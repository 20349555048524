import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Header } from 'src/app/enums/header';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor(@Inject(DOCUMENT) private document: Document, private router: Router) { }

  ngOnInit(): void {
    // Scroll to section if URL includes 'deep link'
    const header = this.router.url.split('/').pop()?.toLowerCase() as Header;
    if (Object.values(Header).includes(header)) {
      const element = this.document.getElementById(header)
      element != null ? element.scrollIntoView({ behavior: 'smooth' }) : null;
    }
  }
}

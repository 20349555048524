
<div class="league-wrapper">
    <app-gradient-overlay [right]="true"></app-gradient-overlay>
    <div id="leagues" class="league-container">
        <div class="league-content">
            <div class="players-container"></div>
    
            <div class="heading-container">
                <div class="heading-content">
                    <div class="round-img-container">
                        <img src="../../../assets/orange_star.png">
                    </div>
                    <div class="heading">
                        <div class="heading--text">
                            <h2>Draft your team and <span>earn tokens</span> for free</h2>
                        </div>
                    </div>
                    <div class="body-16">
                        Draft your team and earn tokens by scoring points while playing for free
                    </div>
                    <div class="action">
                        <button class="button button--primary" (click)="goToDraftzyRegister()">
                            <span>Sign up</span>
                            <fa-icon [icon]="['fas', 'arrow-right-long']"></fa-icon>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="footer-container">
    <div class="footer-content">
        <div class="heading-container">
            <div class="heading">
                <span class="body-16 text--secondary">Draftzy</span>
            </div>
            <div class="body-16">
                Play to win, collect points and earn tokens
            </div>
            <div class="body-14">
                © 2024. All rights reserved
            </div>
        </div>
        <div class="pages-wrapper">
            <div class="pages-container">
                <div class="pages body-14">
                    <span (click)="scroll(Header.TokenSale)" routerLink="/tokensale">Token sale</span>
                    <span (click)="scroll(Header.AboutUs)" routerLink="/aboutus">About</span>
                    <span (click)="scroll(Header.Points)" routerLink="/points">Points</span>
                    <span (click)="scroll(Header.Leagues)" routerLink="/leagues">Leagues</span>
                    <span (click)="openTerms()">Terms & Conditions</span>
                </div>
            </div>
            <div class="pages-container">
                <div class="pages body-14">
                    <span (click)="scroll(Header.Roadmap)" routerLink="/roadmap">Roadmap</span>
                    <span (click)="scroll(Header.Matches)" routerLink="/matches">Matches</span>
                    <span (click)="scroll(Header.Teams)" routerLink="/teams">Teams</span>
                    <span (click)="scroll(Header.Socials)" routerLink="/socials">Socials</span>
                    <span (click)="openPrivacyPolicy()">Privacy policy</span>
                </div>
            </div>
        </div>
        
        <div class="socials">
            <fa-icon [icon]="['fab', 'telegram']" (click)="goToTelegram()"></fa-icon>
            <fa-icon [icon]="['fab', 'x-twitter']" (click)="goToTwitter()"></fa-icon>
            <!-- <fa-icon [icon]="['fab', 'reddit']" (click)="goToReddit()"></fa-icon> -->
        </div>
        <div class="vector"></div>
    </div>
</div>
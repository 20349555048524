<div class="mb-24 input-wrapper" [ngClass]="{ 'input--error-bottom': controlName.invalid && (controlName.dirty || controlName.touched) }">
    <span class="input--label">{{ label }}</span>
    <img *ngIf="inputPrefixIcon === InputPrefixIcon.ETH" class="icon-prefix icon-prefix--eth" src="../../../assets/eth.png">
    <img *ngIf="inputPrefixIcon === InputPrefixIcon.DZY" class="icon-prefix" src="../../../assets/dzy_coin.png">
    <input
      class="input"
      [type]="inputType"
      [formControl]="controlName"
      [placeholder]="placeholder"
      (keyup)="valueChanged()"
      [ngClass]="{ 'input--error-styles': controlName.invalid && (controlName.dirty || controlName.touched), 'input--icon-prefix': inputPrefixIcon !== InputPrefixIcon.NONE }" />
    <div *ngIf="!confirmPassword && controlName.invalid && (controlName.dirty || controlName.touched)" class="input--error">{{ getErrorMessage() }}</div>
    <div *ngIf="confirmPassword && groupName?.invalid && (controlName.dirty || controlName.touched)" class="input--error">{{ getErrorMessage() }}</div>
  </div>
  
<div class="tokenomics-wrapper">
    <app-gradient-overlay [left]="true"></app-gradient-overlay>
    <div id="tokenomics" class="tokenomics-container">
        <div class="tokenomics-content">
            <div class="heading-container">
                <div class="heading-content">
                    <div class="round-img-container">
                        <img src="../../../assets/orange_star.png">
                    </div>
                    <div class="heading">
                        <div class="heading--text">
                            <h2><span>Tokenomics</span> with rewards & sales</h2>
                        </div>
                    </div>
                    <div class="body-16">
                        Our tokenomics consists of developer funds, marketing & promotions and a base reward pool for our users.
                        Last but not least we also have our pre-sale and public sale for our true supporters.
                    </div>
                </div>
            </div>
    
            <div class="chart-container">
                <app-chart></app-chart>
            </div>
        </div>
    </div>
</div>
import { Component } from '@angular/core';
import { Chart, ChartOptions } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
Chart.register(ChartDataLabels);

@Component({
  selector: 'app-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss']
})
export class ChartComponent {
  screenWidth = window.innerWidth;

  public pieChartOptions: ChartOptions<'pie'> = {
    responsive: true,
    plugins: {
      datalabels: {
        display: true,
        formatter: (value) => value + "%",
        font: {
          family: 'Outfit',
          style: 'normal',
          size: this.screenWidth >= 500 ? 16 : 14
        }
      },
      legend: {
        display: true,
        labels: {
          font: {
            family: 'Outfit',
            style: 'normal',
            size: 16
          },
          usePointStyle: true,
          pointStyle: 'rectRounded'
        }
      },
      tooltip: {
        titleFont: {
          family: 'Outfit',
          style: 'normal',
          size: 14
        },
        bodyFont: {
          family: 'Outfit',
          style: 'normal',
          size: 14
        },
        footerFont: {
          family: 'Outfit',
          style: 'normal',
          size: 14,
        },
        titleColor: '#66626E',
        bodyColor: '#66626E',
        footerColor: '#66626E',
        backgroundColor: 'rgba(255, 255, 255, 0.6)',
        padding: 10,
        boxPadding: 10
      }
    }
  };
  public pieChartLabels = [ 'Pre-sale', 'Public sale', 'User rewards', 'Marketing & Promotions', 'Developer funds' ];
  public pieChartDatasets = [ {
    data: [ 10, 50, 20, 10, 10 ],
    backgroundColor: [
      'rgba(29, 145, 252, 0.5)',
      'rgba(29, 145, 252, 0.4)',
      'rgba(29, 145, 252, 0.3)',
      'rgba(29, 145, 252, 0.2)',
      'rgba(29, 145, 252, 0.1)'
    ]
  } ];
  
  constructor() {}
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { InputComponent } from '../shared/input/input.component';
import { InputType } from 'src/app/enums/input-type';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { Environment } from 'src/app/enums/environment';
import { createWeb3Modal, defaultWagmiConfig } from '@web3modal/wagmi';
import { mainnet, sepolia } from 'viem/chains';
import { Config, GetAccountReturnType, getAccount, reconnect, sendTransaction } from '@wagmi/core';
import { Web3Modal } from '@web3modal/wagmi/dist/types/src/client';
import { parseEther } from 'viem';
import { SaleTransactionCompletedDialogComponent } from '../shared/dialogs/sale-transaction-completed-dialog/sale-transaction-completed-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { InputPrefixIcon } from 'src/app/enums/input-prefix-icon';
import { SaleType } from 'src/app/enums/sale-type';
import { DecimalPipe } from '@angular/common';

@Component({
  selector: 'app-token-sale-hero',
  templateUrl: './token-sale-hero.component.html',
  styleUrl: './token-sale-hero.component.scss'
})
export class TokenSaleHeroComponent  implements OnInit {
  @ViewChild('dzyInput') dzyInput!: InputComponent;
  @ViewChild('ethInput') ethInput!: InputComponent;

  InputType = InputType;
  InputPrefixIcon = InputPrefixIcon;
  SaleType = SaleType;

  contractAddressValue = environment.contractAddress;
  showCopied = false;
  saleMode = SaleType.WEB3;

  form!: FormGroup;
  contractAddress = new FormControl({ value: this.contractAddressValue, disabled: true });
  draftzyAmount = new FormControl<string | null>('100000', { validators: [Validators.required] });
  ethAmount = new FormControl<string | null>('0.01', { validators: [Validators.required, Validators.min(0.001)] });

  web3Modal!: Web3Modal;
  web3Config!: Config;
  web3Account!: GetAccountReturnType;
  web3TestNet = [sepolia] as const;
  web3MainNet = [mainnet] as const;

  constructor(private matDialog: MatDialog,
    private decimalPipe: DecimalPipe
  ) {}

  async ngOnInit(): Promise<void> {
    this.form = new FormGroup({
      draftzyAmount: this.draftzyAmount,
      ethAmount: this.ethAmount
    });

    this.setTokenAmount(0.01);
    await this.setupWalletConnect();
  }

  setTokenAmount(ethAmount: number): void {
    this.ethChanged(ethAmount);
    this.dzyChanged(ethAmount * 10000000);
  }

  dzyChanged(value: any): void {
    const ethAmount = this.decimalPipe.transform(Number((value / 10000000).toPrecision(9)), '1.0-6', 'en-US');
    this.ethAmount.setValue(ethAmount, { emitEvent: false, emitModelToViewChange: true, emitViewToModelChange: false });
  }

  ethChanged(value: any): void {
    const draftzyAmount = this.decimalPipe.transform(Number((value * 10000000).toPrecision(9)), '1.2-2', 'en-US');
    this.draftzyAmount.setValue(draftzyAmount, { emitEvent: false, emitModelToViewChange: true, emitViewToModelChange: false });
  }

  setShowCopied(): void {
    this.showCopied = true;
    setTimeout(() => {
      this.showCopied = false;
    }, 5000);
  }

  changeSaleMode(saleType: SaleType): void {
    this.saleMode = saleType;
  }

  private async setupWalletConnect(): Promise<void> {
    const projectId = environment.walletConnectProjectId;
    const metadata = {
      name: 'DZY',
      description: 'Buy DZY tokens',
      url: environment.walletConnectDomain,
      icons: ['https://avatars.githubusercontent.com/u/37784886']
    };

    const chains = environment.env === Environment.PROD ? this.web3MainNet : this.web3TestNet;
    this.web3Config = defaultWagmiConfig({
      chains,
      projectId,
      metadata
    });
    reconnect(this.web3Config);

    this.web3Modal = createWeb3Modal({
      wagmiConfig: this.web3Config,
      projectId,
      themeMode: 'light'
    });

    this.subscribeToWeb3ModalState();
  }

  public openWeb3Modal(): void {
    this.web3Modal.open();
  }

  private subscribeToWeb3ModalState(): void {
    this.web3Modal.subscribeState(async newState => {
      const account = getAccount(this.web3Config);
      const accountIsConnected = account?.isConnected;

      if (accountIsConnected && newState.open && this.ethAmount.value != null && Number(this.ethAmount.value) > 0) {
        const txHash = await sendTransaction(this.web3Config, {
          to: environment.contractAddress as `0x${string}`,
          value: parseEther(this.ethAmount.value.toString())
        });

        if (txHash != null) {
          this.web3Modal.close();
          this.matDialog.open(SaleTransactionCompletedDialogComponent, { data: txHash, panelClass: 'sale-transaction-completed-dialog' });
        }
      }
    });
  }
}

<div id="aboutus" class="info-container">
    <div class="info-content">
        <div class="heading-container">
            <div class="heading">
                <h2><p>Why</p> Draftzy?</h2>
                <span class="body-14">Earn tokens for free by playing and scoring points, a fun Fantasy Sports experience for everyone</span>
            </div>
        </div>
        <div class="cards">
            <div class="overlay"></div>
            <div class="card">
                <div class="icon">
                    <img src="../../../assets/icon_wallet.svg">
                </div>
                <div>
                    <h3>Earn free tokens</h3>
                    <div class="body-14">
                        Earn tokens for free by playing and scoring points. Your points will be rewarded as tokens. Tokens can be used to buy boosts for your team or withdrawn to your own wallet.
                    </div>
                </div>
            </div>

            <div class="card">
                <div class="icon">
                    <img src="../../../assets/icon_info_4.svg">
                </div>
                <div>
                    <h3>Token burn</h3>
                    <div class="body-14">
                        In order to keep our free token rewards sustainable, we will burn an extra 25% of the rewarded tokens when rewards are given out every single time. Making sure your tokens stay valuable.
                    </div>
                </div>
            </div>
            <div class="card">
                <div class="icon">
                    <img src="../../../assets/icon_medal_star.svg">
                </div>
                <div>
                    <h3>Compete for #1</h3>
                    <div class="body-14">
                        Draft and manage your team in a league. We offer an easy and straight forward way to play for everyone. Score as many points as possible with good results. Are you feeling lucky yet?
                    </div>
                </div>
            </div>

            <div class="card">
                <div class="icon">
                    <img src="../../../assets/icon_lock.svg">
                </div>
                <div>
                    <h3>Safe & secure</h3>
                    <div class="body-14">
                        Our platform is safe and secure for everyone. Your points and tokens are only accessible by you, while you are also the only one who retains ownership over the tokens at all times.
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
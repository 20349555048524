import { DOCUMENT } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { Header } from 'src/app/enums/header';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
  Header = Header;

  constructor(@Inject(DOCUMENT) private document: Document) {}

  scroll(header: Header) {
    const element = this.document.getElementById(header)
    element != null ? element.scrollIntoView({ behavior: 'smooth' }) : null;
  }

  goToTelegram(): void {
    window.open(environment.telegramUrl, '_blank');
  }

  goToTwitter(): void {
    window.open(environment.twitterUrl, '_blank');
  }

  goToReddit(): void {
    window.open(environment.redditUrl, '_blank');
  }

  openTerms(): void {
    window.open(environment.termsUrl, '_blank');
  }

  openPrivacyPolicy(): void {
    window.open(environment.privacyUrl, '_blank');
  }
}

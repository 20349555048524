import { DOCUMENT } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { Header } from 'src/app/enums/header';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-hero',
  templateUrl: './hero.component.html',
  styleUrls: ['./hero.component.scss']
})
export class HeroComponent {
  tokenSale = environment.tokenSale;
  draftzyUrl = environment.draftzyUrl;
  Header = Header;

  constructor(@Inject(DOCUMENT) private document: Document) { }

  goToDraftzyRegister(): void {
    window.open(environment.draftzyRegisterUrl, "_blank");
  }

  goToDraftzySale(): void {
    window.open(environment.draftzySaleUrl, "_blank");
  }

  scroll(header: Header): void {
    const element = this.document.getElementById(header)
    element != null ? element.scrollIntoView({ behavior: 'smooth' }) : null;
  }
}

import { Component, HostListener, OnInit } from '@angular/core';

@Component({
  selector: 'app-roadmap',
  templateUrl: './roadmap.component.html',
  styleUrl: './roadmap.component.scss'
})
export class RoadmapComponent implements OnInit {
  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.screenWidth = window.innerWidth;
    this.mobile = this.screenWidth < 1280;
  }

  screenWidth = 0;
  mobile = false;

  ngOnInit(): void {
    this.screenWidth = window.innerWidth;
    this.mobile = this.screenWidth < 1280;
  }
}

import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-points-info',
  templateUrl: './points-info.component.html',
  styleUrls: ['./points-info.component.scss']
})
export class PointsInfoComponent {
  constructor() { }

  goToDraftzyRegister(): void {
    window.open(environment.draftzyRegisterUrl, "_blank");
  }
}

import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-gradient-overlay',
  templateUrl: './gradient-overlay.component.html',
  styleUrl: './gradient-overlay.component.scss'
})
export class GradientOverlayComponent {
  @Input() left = false;
  @Input() right = false;
}

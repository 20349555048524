import { Component } from '@angular/core';

@Component({
  selector: 'app-hero-overlay',
  templateUrl: './hero-overlay.component.html',
  styleUrl: './hero-overlay.component.scss'
})
export class HeroOverlayComponent {

}

import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-team-info',
  templateUrl: './team-info.component.html',
  styleUrls: ['./team-info.component.scss']
})
export class TeamInfoComponent {
  constructor() { }

  goToDraftzyRegister(): void {
    window.open(environment.draftzyRegisterUrl, "_blank");
  }
}

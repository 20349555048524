<div class="elements">
    <div class="circle-bottom-hero">
        <svg xmlns="http://www.w3.org/2000/svg" width="243" height="110" viewBox="0 0 243 110" fill="none">
            <ellipse opacity="0.07" cx="121.5" cy="116" rx="121.5" ry="116" fill="url(#paint0_linear_714_15879)"/>
            <defs>
              <linearGradient id="paint0_linear_714_15879" x1="0" y1="0" x2="257.583" y2="17.5128" gradientUnits="userSpaceOnUse">
                <stop stop-color="#1D91FC"/>
                <stop offset="1" stop-color="#0075FF"/>
              </linearGradient>
            </defs>
          </svg>
    </div>
    <div class="circle-bottomleft-hero">
        <svg xmlns="http://www.w3.org/2000/svg" width="243" height="110" viewBox="0 0 243 110" fill="none">
            <circle opacity="0.2" cx="121.5" cy="55" r="55" fill="#EBF0F9"/>
          </svg>
        <svg xmlns="http://www.w3.org/2000/svg" width="78" height="78" viewBox="0 0 78 78" fill="none">
            <circle opacity="0.5" cx="39" cy="39" r="39" fill="#EBF0F9"/>
          </svg>
          
    </div>
    <div class="circle-top-hero">
        <svg xmlns="http://www.w3.org/2000/svg" width="176" height="204" viewBox="0 0 176 204" fill="none">
            <ellipse opacity="0.5" cx="106.5" cy="102" rx="106.5" ry="102" fill="url(#paint0_linear_696_14984)"/>
            <defs>
              <linearGradient id="paint0_linear_696_14984" x1="-37" y1="102" x2="178.848" y2="102" gradientUnits="userSpaceOnUse">
                <stop stop-color="#E8EEF9"/>
                <stop offset="1" stop-color="#E0E6F2" stop-opacity="0"/>
              </linearGradient>
            </defs>
          </svg>
    </div>
    <div class="big-circle"></div>
    <div class="dotted-line">
        <svg xmlns="http://www.w3.org/2000/svg" width="549" height="215" viewBox="0 0 549 215" fill="none">
            <path d="M8.62352 214.5C-0.209815 196.333 -8.67648 157.3 28.1235 146.5C74.1235 133 99.6235 200.5 167.624 188C235.624 175.5 259.124 134.5 354.624 136.5C450.124 138.5 484.124 241 515.624 195C547.124 149 555.374 74.3821 541.124 46.5C529.624 24 514.624 5 462.624 -10" stroke="url(#paint0_linear_714_15903)" stroke-linecap="round" stroke-dasharray="4 4"/>
            <defs>
              <linearGradient id="paint0_linear_714_15903" x1="1" y1="-10" x2="569.162" y2="79.8379" gradientUnits="userSpaceOnUse">
                <stop stop-color="#1D91FC"/>
                <stop offset="1" stop-color="#0075FF"/>
              </linearGradient>
            </defs>
          </svg>
    </div>
</div>
<div id="socials" class="signup-container">
    <div class="signup-content">
        <div class="heading-container">
            <div class="heading">
                <div class="heading--text" (click)="goToDraftzyRegister()">
                    <h2><span>Join</span> Draftzy now!</h2>
                </div>
            </div>
            <div class="body-16">
                Keep an eye out for updates and promotions regarding Draftzy by joining our socials
            </div>
            <div class="socials">
                <fa-icon [icon]="['fab', 'telegram']" (click)="goToTelegram()"></fa-icon>
                <fa-icon [icon]="['fab', 'x-twitter']" (click)="goToTwitter()"></fa-icon>
                <!-- <fa-icon [icon]="['fab', 'reddit']" (click)="goToReddit()"></fa-icon> -->
            </div>
        </div>
    </div>
</div>
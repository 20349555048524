<div id='tokensale' class="tokens-card-container">
  <div class="tokens-card-content">
  <div class="heading-container">
    <div class="heading">
        <h2><p>Token Sale</p> live!</h2>
        <span class="body-14">
          Buy DZY tokens and get a permanent 20% points boost for your account now, or by connecting your wallet in the future!
        </span>
    </div>
  </div>
  
  <div class="overlay"></div>
  <div class="tokens-card-wrapper">
        <div class="tokens-card-header">
          <div class="tokens-header">
            <h2>DZY sale</h2>
          </div>

          <div class="tokens-actions">
            <div
              class="tokens-action body-12"
              [ngClass]="{ 'tab-bar--active': saleMode === SaleType.WEB3, 'tab-bar--disabled': saleMode !== SaleType.WEB3 }"
              (click)="changeSaleMode(SaleType.WEB3)"
              (keydown)="changeSaleMode(SaleType.WEB3)"
              role="none">
              Web3 Connect
            </div>
            <div
              class="tokens-action body-12"
              [ngClass]="{ 'tab-bar--active': saleMode === SaleType.MANUAL, 'tab-bar--disabled': saleMode !== SaleType.MANUAL }"
              (click)="changeSaleMode(SaleType.MANUAL)"
              (keydown)="changeSaleMode(SaleType.MANUAL)"
              role="none">
              Manual transfer
            </div>
          </div>
        </div>

        <div [hidden]="saleMode !== SaleType.MANUAL" class="divider divider--p30"></div>

        <div [hidden]="saleMode !== SaleType.MANUAL" class="tokens-card-body">
          <div class="tokens-contract-wrapper">
            <div class="tokens-contract-header">
              <h2>Token contract address</h2>
              <span class="body-14">
                Purchase DZY tokens by sending ETH to the contract address manually.<br />
                The contract will automatically send the correct amount of DZY tokens back to your wallet.<br /><br />
                Copy the contract address by clicking the copy button below, to make sure you send your ETH to the correct contract address.
              </span>
            </div>
            <div class="tokens-contract-address">
              <app-input class="contract-input" [controlName]="contractAddress" [label]="'Token contract address'"></app-input>
              <fa-icon *ngIf="!showCopied" class="tokens-contract-address--icon" [icon]="['fas', 'copy']" [cdkCopyToClipboard]="contractAddressValue" (click)="setShowCopied()"></fa-icon>
              <div *ngIf="showCopied" class="copied">
                <fa-icon class="tokens-contract-address--icon tokens-contract-address--icon__success" [icon]="['fas', 'check']" [cdkCopyToClipboard]="contractAddressValue"></fa-icon>
                <span class="body-14 body-14--light">Copied</span>
              </div>
            </div>
          </div>
        </div>
    
        <div [hidden]="saleMode !== SaleType.WEB3" class="divider divider--p30"></div>
    
        <div [hidden]="saleMode !== SaleType.WEB3" class="tokens-card-amount-wrapper">
          <h2>Calculate DZY amount</h2>
          <div class="tokens-card-presets">
            <span class="body-14">Presets</span>
            <div class="tokens-card-presets-wrapper">
              <div class="preset body-14" [ngClass]="{ 'preset--active': ethAmount.value === '0.01' }" (click)="setTokenAmount(0.01)" (keydown)="setTokenAmount(0.01)" role="none">0.01 ETH</div>
              <div class="preset body-14" [ngClass]="{ 'preset--active': ethAmount.value === '0.1' }" (click)="setTokenAmount(0.1)" (keydown)="setTokenAmount(0.1)" role="none">0.1 ETH</div>
              <div class="preset body-14" [ngClass]="{ 'preset--active': ethAmount.value === '1' }" (click)="setTokenAmount(1)" (keydown)="setTokenAmount(1)" role="none">1 ETH</div>
              <div class="preset body-14" [ngClass]="{ 'preset--active': ethAmount.value === '5' }" (click)="setTokenAmount(5)" (keydown)="setTokenAmount(5)" role="none">5 ETH</div>
              <div class="preset body-14" [ngClass]="{ 'preset--active': ethAmount.value === '10' }" (click)="setTokenAmount(10)" (keydown)="setTokenAmount(10)" role="none">10 ETH</div>
            </div>
          </div>
          <div class="tokens-card-amount">
            <app-input
              #ethInput
              class="amount-input"
              [controlName]="ethAmount"
              (modelChangeEvent)="ethChanged($event)"
              [label]="'Send ETH'"
              [placeholder]="'ETH amount'"
              [inputType]="InputType.Default"
              [inputPrefixIcon]="InputPrefixIcon.ETH"
              [numberOnly]="true">
            </app-input>
            <app-input
              #dzyInput
              class="amount-input"
              [controlName]="draftzyAmount"
              (modelChangeEvent)="dzyChanged($event)"
              [label]="'Receive DZY'"
              [placeholder]="'DZY amount'"
              [inputType]="InputType.Default"
              [inputPrefixIcon]="InputPrefixIcon.DZY"
              [numberOnly]="true">
            </app-input>
          </div>
        </div>
    
        <div [hidden]="saleMode !== SaleType.WEB3" class="divider divider--p30"></div>
    
        <div [hidden]="saleMode !== SaleType.WEB3" class="tokens-card-connect-wrapper">
          <div *ngIf="false" class="tokens-card-connect-amount">
            <span class="body-16">Total ETH amount:</span>
            <span class="body-16 body-16--secondary bold">{{ ethAmount.value }} ETH</span>
          </div>
          <button class="button button--primary w--100 connect-web3-btn" type="button" (click)="openWeb3Modal()" [disabled]="form.invalid">
            <span>Connect wallet</span>
          </button>
        </div>
      </div>
  </div>
  </div>
  
import { Environment } from 'src/app/enums/environment';

export const environment = {
    env: Environment.PROD,
    draftzyUrl: 'https://app.draftzy.io',
    draftzyRegisterUrl: 'https://app.draftzy.io/register',
    draftzySaleUrl: 'https://app.draftzy.io/tokensale',
    termsUrl: 'https://draftzy.io/assets/files/Terms-Conditions.pdf',
    privacyUrl: 'https://draftzy.io/assets/files/Privacy-Policy.pdf',
    twitterUrl: 'https://twitter.com/Draftzy_io',
    redditUrl: 'https://www.reddit.com/r/draftzy/',
    telegramUrl: 'https://t.me/draftzy',
    tokenSale: true,
    contractAddress: '0x5F1890371cE89407CcdBdfFa4272F0121Eb3D3e3',
    walletConnectProjectId: '743a3af7ff57a323788411a811a76c42',
    walletConnectDomain: 'https://app.draftzy.io',
    etherscanUrl: 'https://etherscan.io/tx'
};

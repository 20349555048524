export enum Header {
    Hero = 'hero',
    TokenSale = 'tokensale',
    AboutUs = 'aboutus',
    Points = 'points',
    Leagues = 'leagues',
    Matches = 'matches',
    Teams = 'teams',
    Tokenomics = 'tokenomics',
    Roadmap = 'roadmap',
    Socials = 'socials'
}
<div *ngIf="!white" class="elements">
    <div class="circle-topleft">
      <svg xmlns="http://www.w3.org/2000/svg" width="128" height="128" viewBox="0 0 128 128" fill="none">
        <circle opacity="0.1" cx="64" cy="64" r="54" stroke="#dde3f0" stroke-width="20" />
      </svg>
    </div>
    <div class="circle-middleleft">
      <svg xmlns="http://www.w3.org/2000/svg" width="48" height="46" viewBox="0 0 48 46" fill="none">
        <ellipse cx="24" cy="23" rx="24" ry="23" fill="#dde3f0" fill-opacity="0.1" />
      </svg>
      <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
        <circle opacity="0.15" cx="13" cy="13" r="13" fill="#dde3f0" />
      </svg>
    </div>
    <div class="circle-bottomleft">
      <svg xmlns="http://www.w3.org/2000/svg" width="70" height="70" viewBox="0 0 70 70" fill="none">
        <circle cx="35" cy="35" r="29" stroke="#dde3f0" stroke-opacity="0.1" stroke-width="12" />
      </svg>
      <svg xmlns="http://www.w3.org/2000/svg" width="38" height="38" viewBox="0 0 38 38" fill="none">
        <circle cx="19" cy="19" r="19" fill="#dde3f0" fill-opacity="0.2" />
      </svg>
    </div>
    <div class="circle-middle">
      <svg xmlns="http://www.w3.org/2000/svg" width="46" height="46" viewBox="0 0 46 46" fill="none">
        <circle cx="23" cy="23" r="23" fill="#dde3f0" fill-opacity="0.25" />
      </svg>
    </div>
    <div class="circle-middletop">
      <svg xmlns="http://www.w3.org/2000/svg" width="48" height="34" viewBox="0 0 48 34" fill="none">
        <circle cx="24" cy="10" r="24" fill="#dde3f0" fill-opacity="0.15" />
      </svg>
      <svg xmlns="http://www.w3.org/2000/svg" width="29" height="28" viewBox="0 0 29 28" fill="none">
        <circle cx="14.2529" cy="14" r="10.5" stroke="#dde3f0" stroke-opacity="0.2" stroke-width="7" />
      </svg>
    </div>
    <div class="circle-middlebottom">
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        <circle opacity="0.1" cx="10" cy="10" r="10" fill="#dde3f0" />
      </svg>
    </div>
    <div class="circle-topright">
      <svg xmlns="http://www.w3.org/2000/svg" width="78" height="78" viewBox="0 0 78 78" fill="none">
        <circle cx="39" cy="39" r="33" stroke="#dde3f0" stroke-opacity="0.2" stroke-width="12" />
      </svg>
      <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
        <circle cx="15" cy="15" r="15" fill="#dde3f0" fill-opacity="0.4" />
      </svg>
    </div>
    <div class="circle-middleright">
      <svg xmlns="http://www.w3.org/2000/svg" width="128" height="128" viewBox="0 0 128 128" fill="none">
        <circle opacity="0.1" cx="64" cy="64" r="54" stroke="#dde3f0" stroke-width="20" />
      </svg>
      <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" fill="none">
        <circle opacity="0.2" cx="25" cy="25" r="25" fill="#dde3f0" />
      </svg>
    </div>
    <div class="circle-bottomright">
      <svg xmlns="http://www.w3.org/2000/svg" width="25" height="38" viewBox="0 0 25 38" fill="none">
        <ellipse opacity="0.2" cx="20.044" cy="19" rx="20.044" ry="19" fill="#dde3f0" />
      </svg>
      <svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18" fill="none">
        <ellipse cx="9.49451" cy="9" rx="9.49451" ry="9" fill="#dde3f0" fill-opacity="0.2" />
      </svg>
    </div>
</div>

<div *ngIf="white" class="elements">
    <div class="circle-topleft">
      <svg xmlns="http://www.w3.org/2000/svg" width="128" height="128" viewBox="0 0 128 128" fill="none">
        <circle opacity="0.1" cx="64" cy="64" r="54" stroke="white" stroke-width="20" />
      </svg>
    </div>
    <div class="circle-middleleft">
      <svg xmlns="http://www.w3.org/2000/svg" width="48" height="46" viewBox="0 0 48 46" fill="none">
        <ellipse cx="24" cy="23" rx="24" ry="23" fill="white" fill-opacity="0.1" />
      </svg>
      <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
        <circle opacity="0.15" cx="13" cy="13" r="13" fill="white" />
      </svg>
    </div>
    <div class="circle-bottomleft">
      <svg xmlns="http://www.w3.org/2000/svg" width="70" height="70" viewBox="0 0 70 70" fill="none">
        <circle cx="35" cy="35" r="29" stroke="white" stroke-opacity="0.1" stroke-width="12" />
      </svg>
      <svg xmlns="http://www.w3.org/2000/svg" width="38" height="38" viewBox="0 0 38 38" fill="none">
        <circle cx="19" cy="19" r="19" fill="white" fill-opacity="0.2" />
      </svg>
    </div>
    <div class="circle-middle">
      <svg xmlns="http://www.w3.org/2000/svg" width="46" height="46" viewBox="0 0 46 46" fill="none">
        <circle cx="23" cy="23" r="23" fill="white" fill-opacity="0.25" />
      </svg>
    </div>
    <div class="circle-middletop">
      <svg xmlns="http://www.w3.org/2000/svg" width="48" height="34" viewBox="0 0 48 34" fill="none">
        <circle cx="24" cy="10" r="24" fill="white" fill-opacity="0.15" />
      </svg>
      <svg xmlns="http://www.w3.org/2000/svg" width="29" height="28" viewBox="0 0 29 28" fill="none">
        <circle cx="14.2529" cy="14" r="10.5" stroke="white" stroke-opacity="0.2" stroke-width="7" />
      </svg>
    </div>
    <div class="circle-middlebottom">
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        <circle opacity="0.1" cx="10" cy="10" r="10" fill="white" />
      </svg>
    </div>
    <div class="circle-topright">
      <svg xmlns="http://www.w3.org/2000/svg" width="78" height="78" viewBox="0 0 78 78" fill="none">
        <circle cx="39" cy="39" r="33" stroke="white" stroke-opacity="0.2" stroke-width="12" />
      </svg>
      <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
        <circle cx="15" cy="15" r="15" fill="white" fill-opacity="0.4" />
      </svg>
    </div>
    <div class="circle-middleright">
      <svg xmlns="http://www.w3.org/2000/svg" width="128" height="128" viewBox="0 0 128 128" fill="none">
        <circle opacity="0.1" cx="64" cy="64" r="54" stroke="white" stroke-width="20" />
      </svg>
      <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" fill="none">
        <circle opacity="0.2" cx="25" cy="25" r="25" fill="white" />
      </svg>
    </div>
    <div class="circle-bottomright">
      <svg xmlns="http://www.w3.org/2000/svg" width="25" height="38" viewBox="0 0 25 38" fill="none">
        <ellipse opacity="0.2" cx="20.044" cy="19" rx="20.044" ry="19" fill="white" />
      </svg>
      <svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18" fill="none">
        <ellipse cx="9.49451" cy="9" rx="9.49451" ry="9" fill="white" fill-opacity="0.2" />
      </svg>
    </div>
</div>
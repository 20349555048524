<div class="team-wrapper">
    <app-gradient-overlay [right]="true"></app-gradient-overlay>
    <div id="teams" class="team-container">
        <div class="team-content">
            <div class="team-info-container"></div>
    
            <div class="heading-container">
                <div class="heading-content">
                    <div class="round-img-container">
                        <img src="../../../assets/orange_thumb.png">
                    </div>
                    <div class="heading">
                        <div class="heading--text">
                            <h2><span>Create</span> your perfect fantasy team</h2>
                        </div>
                    </div>
                    <div class="body-16">
                        Choose your favourite players and draft a team to gain as much tokens as possible while playing for free
                    </div>
                    <div class="action">
                        <button class="button button--primary" (click)="goToDraftzyRegister()">
                            <span>Sign up</span>
                            <fa-icon [icon]="['fas', 'arrow-right-long']"></fa-icon>
                        </button>
                    </div>
                </div>
            </div>
            
        </div>
    </div>
</div>
<div id="roadmap" class="roadmap-container">
    <div class="heading">
        <h2><p>Draftzy</p> roadmap</h2>
            <span class="body-14">Draftzy will receive new leagues & games, while your DZY tokens will receive a boost by being listed on exchanges and by forming strategic partnerships along the way.</span>
    </div>
    <div class="roadmap-content">
        <div class="roadmap-wrapper roadmap-wrapper--left">
            <div class="roadmap-items">
                <div class="roadmap-card-wrapper">
                    <app-roadmap-circles-overlay [white]="true"></app-roadmap-circles-overlay>
                    <div class="roadmap-card roadmap-card--left active-phase">
                        <div class="roadmap-card--content">
                            <h2>Phase 1</h2>
                            <ul>
                                <li>Launch of the Draftzy platform</li>
                                <li>Launch of marketing campaign</li>
                                <li>Launch of token sale</li>
                                <li>Exploration of partnerships</li>
                            </ul>
                        </div>
                        <div *ngIf="mobile" class="mobile-divider"></div>
                    </div>
                </div>

                <div class="roadmap-card--gap"></div>

                <div class="roadmap-card-wrapper">
                    <app-roadmap-circles-overlay></app-roadmap-circles-overlay>
                    <div class="roadmap-card roadmap-card--left">
                        <div *ngIf="!mobile" class="roadmap-card--content">
                            <h2>Phase 3</h2>
                            <ul>
                                <li class="body-16">Launch of Draftzy F1 leagues</li>
                                <li class="body-16">Marketing for Draftzy F1 leagues</li>
                                <li class="body-16">Expansion of Exchange listings</li>
                                <li class="body-16">Expansion of partnerships</li>
                            </ul>
                        </div>
                        <div *ngIf="mobile" class="roadmap-card--content">
                            <h2>Phase 2</h2>
                            <ul>
                                <li class="body-16">Launch of first Draftzy leagues</li>
                                <li class="body-16">Launch of token rewards</li>
                                <li class="body-16">Launch of Exchange listings</li>
                                <li class="body-16">Launch of first partnerships</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    
        <div class="roadmap-divider-wrapper">
            <div class="roadmap-divider">
                <div class="round-img-container round-img-container--phase-1">
                    <img src="../../../assets/draftzy_icon.svg">
                </div>
                <div class="round-img-container round-img-container--phase-2">
                    <img src="../../../assets/draftzy_icon.svg">
                </div>
                <div class="round-img-container round-img-container--phase-3">
                    <img src="../../../assets/draftzy_icon.svg">
                </div>
                <div class="round-img-container round-img-container--phase-4">
                    <img src="../../../assets/draftzy_icon.svg">
                </div>
            </div>
        </div>

        <div class="roadmap-wrapper roadmap-wrapper--right">
            <div class="roadmap-items">
                <div class="roadmap-card--gap"></div>

                <div class="roadmap-card-wrapper">
                    <app-roadmap-circles-overlay></app-roadmap-circles-overlay>
                    <div class="roadmap-card roadmap-card--right">
                        <div *ngIf="!mobile" class="roadmap-card--content">
                            <h2>Phase 2</h2>
                            <ul>
                                <li class="body-16">Launch of first Draftzy leagues</li>
                                <li class="body-16">Launch of token rewards</li>
                                <li class="body-16">Launch of Exchange listings</li>
                                <li class="body-16">Launch of first partnerships</li>
                            </ul>
                        </div>
                        <div *ngIf="mobile" class="roadmap-card--content">
                            <h2>Phase 3</h2>
                            <ul>
                                <li class="body-16">Launch of Draftzy F1 leagues</li>
                                <li class="body-16">Marketing for Draftzy F1 leagues</li>
                                <li class="body-16">Expansion of Exchange listings</li>
                                <li class="body-16">Expansion of partnerships</li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="roadmap-card--gap"></div>

                <div class="roadmap-card-wrapper">
                    <app-roadmap-circles-overlay></app-roadmap-circles-overlay>
                    <div class="roadmap-card roadmap-card--right">
                        <div class="roadmap-card--content">
                            <h2>Phase 4</h2>
                            <ul>
                                <li class="body-16">Launch of new Draftzy games</li>
                                <li class="body-16">Launch of new Draftzy leagues</li>
                                <li class="body-16">Marketing for new games & leagues</li>
                                <li class="body-16">Expansion of partnerships</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
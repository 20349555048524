<div class="hero-wrapper">
    <app-hero-overlay></app-hero-overlay>
    <div id="hero" class="hero-container">
        <div class="hero-content">
            <div class="hero-heading">
                <div class="heading">
                    <div class="heading--text-icon" [ngClass]="{'token-sale--heading': tokenSale}">
                        <img *ngIf="!tokenSale" src="../../../assets/point_orange.svg">
                        <h1 *ngIf="!tokenSale">Play to win,</h1>
                        <img *ngIf="tokenSale" class="token-sale--image" src="../../../assets/draftzy_icon.svg">
                        <h1 *ngIf="tokenSale">Token Sale live</h1>
                    </div>
                    <div class="heading--text">
                        <h1 *ngIf="!tokenSale">collect points</h1>
                        <h1 *ngIf="tokenSale">get your DZY</h1>
                    </div>
                    <div class="heading--text heading--text--last">
                        <h1 *ngIf="!tokenSale">& earn tokens</h1>
                        <h1 *ngIf="tokenSale">tokens now!</h1>
                    </div>
                </div>
                <div *ngIf="!tokenSale" class="body-16">
                    Draft your favourite team with friends or individually, score as many points as possible and earn tokens for free while playing.
                </div>
                <div *ngIf="tokenSale" class="body-16">
                    Done with missing out on memecoins? Get into the Fantasy Sports world now! Draftzy aims to be the next Web3 Gaming giant in the booming Fantasy Sports market.<br /><br />
                    Buy tokens now and receive a permanent 20% points boost!<br /><br />
                </div>
                <div *ngIf="tokenSale" class="body-12">
                    Note: You can also buy DZY tokens while logged in at <a class="draftzy-url" href="{{draftzyUrl}}" target="_blank">Draftzy</a>, tokens bought while logged in are saved to your Token History.
                </div>
                <div class="action" [ngClass]="{'token-sale--action': tokenSale}">
                    <button *ngIf="tokenSale" class="button button--primary token-sale--buy" (click)="scroll(Header.TokenSale)">
                        <span>Buy tokens</span>
                        <fa-icon [icon]="['fas', 'arrow-right-long']"></fa-icon>
                    </button>
                    <button *ngIf="!tokenSale" class="button button--primary" (click)="goToDraftzyRegister()">
                        <span>Sign up</span>
                        <fa-icon [icon]="['fas', 'arrow-right-long']"></fa-icon>
                    </button>
                </div>
            </div>
            
            <div class="hero-img">
                <img src="../../../assets/hero-img.png">
            </div>
        </div>
    </div>
</div>
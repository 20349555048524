<div id="points" class="points-container">
    <div class="points-content">
        <div class="heading-container">
            <div class="heading">
                <div class="heading--text">
                    <h2><span>Earn</span> tokens while <span>playing</span> for free</h2>
                </div>
            </div>
            <div class="body-16">
                Earn points by playing for free, points are rewarded as tokens and can be used to buy boosts for your team or withdrawn to your own wallet
            </div>
            <div class="action">
                <button class="button button--secondary" (click)="goToDraftzyRegister()">
                    <span>Sign up</span>
                    <fa-icon [icon]="['fas', 'arrow-right-long']"></fa-icon>
                </button>
            </div>
        </div>
        
        <div class="img-container"></div>
    </div>
</div>
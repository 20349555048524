<div class="root-container">
  <div class="header-wrapper">
    <app-header class="header"></app-header>
    <div class="header-border"></div>
  </div>

  <div class="root-content">
    <router-outlet></router-outlet>
  </div>
  
  <app-footer class="footer"></app-footer>
</div>
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-sale-transaction-completed-dialog',
  templateUrl: './sale-transaction-completed-dialog.component.html',
  styleUrls: ['./sale-transaction-completed-dialog.component.scss']
})
export class SaleTransactionCompletedDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public txHash: string,
    private dialogRef: MatDialogRef<SaleTransactionCompletedDialogComponent>
  ) {}

  close(): void {
    this.dialogRef.close();
  }

  openTxHash(): void {
    window.open(`${environment.etherscanUrl}/${this.txHash}`, '_blank');
  }
}

import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-league-info',
  templateUrl: './league-info.component.html',
  styleUrls: ['./league-info.component.scss']
})
export class LeagueInfoComponent {
  constructor() { }

  goToDraftzyRegister(): void {
    window.open(environment.draftzyRegisterUrl, "_blank");
  }
}

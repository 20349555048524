import { style, transition, trigger, animate, animateChild, group, query } from '@angular/animations';
import { DOCUMENT } from '@angular/common';
import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { Header } from 'src/app/enums/header';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  animations: [
    trigger(
      'inOutAnimation', 
      [
        transition(
          ':enter', 
          [
            group([
              query('@appearAnimation', animateChild()),
              style({ width: 0 }),
              animate('750ms ease-out', style({ width: 180 }))
            ])
          ]
        ),
        transition(
          ':leave', 
          [
            group([
              query('@appearAnimation', animateChild()),
              style({ width: 180 }),
              animate('750ms ease-in', style({ width: 0 }))
            ])
          ]
        )
      ]
    ),
    trigger(
      'appearAnimation', 
      [
        transition(
          ':enter', 
          [
            style({ opacity: 0 }),
            animate('500ms ease-in', style({ opacity: 1 }))
          ]
        ),
        transition(
          ':leave', 
          [
            style({ opacity: 1 }),
            animate('500ms ease-out', style({ opacity: 0 }))
          ]
        )
      ]
    )
  ]
})
export class HeaderComponent implements OnInit {
  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.screenWidth = window.innerWidth;
  }

  Header = Header;
  status = false;
  screenWidth = 0;
  hamburgerOpen = false;
  hamburgerPreventCount = 0;
  
  constructor(@Inject(DOCUMENT) private document: Document) { }
  
  ngOnInit(): void {
    this.screenWidth = window.innerWidth;
  }

  scroll(header: Header): void {
    this.closeHamburger();
    const element = this.document.getElementById(header)
    element != null ? element.scrollIntoView({ behavior: 'smooth' }) : null;
  }

  setHamburgerStatus(open: boolean): void {
    this.hamburgerPreventCount++;
    if (this.hamburgerPreventCount % 2 === 0) {
      return;
    }

    this.hamburgerOpen = open;
  }

  goToDraftzy(): void {
    window.open(environment.draftzyUrl, "_blank");
  }

  goToDraftzyRegister(): void {
    window.open(environment.draftzyRegisterUrl, "_blank");
  }

  private closeHamburger(): void {
    if (this.hamburgerOpen) {
      const element = this.document.getElementById('burger-label');
      element != null ? element.click() : null;
    }
  }
}

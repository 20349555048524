import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss']
})
export class SignUpComponent {

  goToTelegram(): void {
    window.open(environment.telegramUrl, '_blank');
  }

  goToTwitter(): void {
    window.open(environment.twitterUrl, '_blank');
  }

  goToReddit(): void {
    window.open(environment.redditUrl, '_blank');
  }

  goToDraftzyRegister(): void {
    window.open(environment.draftzyRegisterUrl, '_blank');
  }
}

<div class="match-wrapper">
    <app-gradient-overlay [left]="true"></app-gradient-overlay>
    <div id="matches" class="match-container">
        <div class="match-content">
            <div class="heading-container">
                <div class="heading-content">
                    <div class="round-img-container">
                        <img src="../../../assets/orange_medal.png">
                    </div>
                    <div class="heading">
                        <div class="heading--text">
                            <h2><span>Keep up</span> with the latest league matches</h2>
                        </div>
                    </div>
                    <div class="body-16">
                        Join your league of choice and keep track of the upcoming matches
                    </div>
                </div>
            </div>
    
            <div class="img-container"></div>
        </div>
    </div>
</div>
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'tokensale', component: HomeComponent },
  { path: 'aboutus', component: HomeComponent },
  { path: 'points', component: HomeComponent },
  { path: 'leagues', component: HomeComponent },
  { path: 'matches', component: HomeComponent },
  { path: 'teams', component: HomeComponent },
  { path: 'socials', component: HomeComponent },
  { path: 'tokenomics', component: HomeComponent },
  { path: 'roadmap', component: HomeComponent },
  { path: '**', redirectTo: ''}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

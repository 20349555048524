<div class="content">
    <app-hero></app-hero>
    <app-token-sale-hero></app-token-sale-hero>
    <app-general-info></app-general-info>
    <app-points-info></app-points-info>
    <app-league-info></app-league-info>
    <app-match-info></app-match-info>
    <app-team-info></app-team-info>
    <app-tokenomics></app-tokenomics>
    <app-roadmap></app-roadmap>
    <app-sign-up></app-sign-up>
</div>
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-roadmap-circles-overlay',
  templateUrl: './roadmap-circles-overlay.component.html',
  styleUrl: './roadmap-circles-overlay.component.scss'
})
export class RoadmapCirclesOverlayComponent {
  @Input() white = false;
}

<div *ngIf="right" class="gradient-overlay">
  <div class="gradient-svg-wrapper">
    <svg xmlns="http://www.w3.org/2000/svg" width="635" height="615" viewBox="0 0 635 615" fill="none">
      <g filter="url(#filter0_f_696_15483)">
        <path d="M473.534 215.257C472.846 179.353 498.897 157.064 512.009 150.407C508.283 163.935 500.402 193.783 498.682 204.95C496.533 218.908 506.85 271.303 514.158 311.03C521.466 350.756 481.057 412.6 397.659 445.669C330.941 472.125 230.434 464.566 188.52 457.48C170.321 446.671 138.051 426.558 154.559 432.57C175.194 440.086 293.842 415.606 331.457 403.796C369.072 391.985 450.105 335.295 459.563 316.183C469.02 297.072 474.394 260.137 473.534 215.257Z" fill="url(#paint0_linear_696_15483)" fill-opacity="0.3"/>
      </g>
      <defs>
        <filter id="filter0_f_696_15483" x="0" y="0.406738" width="665" height="613.798" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix"/>
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
          <feGaussianBlur stdDeviation="75" result="effect1_foregroundBlur_696_15483"/>
        </filter>
        <linearGradient id="paint0_linear_696_15483" x1="515" y1="150.407" x2="128.511" y2="179.588" gradientUnits="userSpaceOnUse">
          <stop stop-color="#1D91FC"/>
          <stop offset="1" stop-color="#0075FF"/>
        </linearGradient>
      </defs>
    </svg>
    <svg xmlns="http://www.w3.org/2000/svg" width="517" height="472" viewBox="0 0 517 472" fill="none">
      <g style="mix-blend-mode:hard-light" filter="url(#filter0_f_696_15484)">
        <path d="M380.723 156.461C380.127 125.412 402.681 106.135 414.032 100.379C410.806 112.078 403.983 137.891 402.495 147.548C400.634 159.618 409.566 204.93 415.893 239.286C422.219 273.641 387.236 327.124 315.036 355.722C257.276 378.601 170.263 372.064 133.977 365.936C118.222 356.589 90.2848 339.195 104.576 344.394C122.44 350.894 225.158 329.724 257.722 319.51C290.287 309.296 360.44 260.27 368.627 243.742C376.815 227.215 381.467 195.274 380.723 156.461Z" fill="url(#paint0_linear_696_15484)" fill-opacity="0.3"/>
      </g>
      <defs>
        <filter id="filter0_f_696_15484" x="0.628906" y="0.378662" width="515.992" height="471.373" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix"/>
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
          <feGaussianBlur stdDeviation="50" result="effect1_foregroundBlur_696_15484"/>
        </filter>
        <linearGradient id="paint0_linear_696_15484" x1="258.625" y1="100.379" x2="258.625" y2="371.751" gradientUnits="userSpaceOnUse">
          <stop stop-color="#9F6FEF"/>
          <stop offset="1" stop-color="#8759D3"/>
        </linearGradient>
      </defs>
    </svg>
  </div>
</div>

<div *ngIf="left" class="gradient-overlay--left">
  <div class="gradient-svg-wrapper">
    <svg xmlns="http://www.w3.org/2000/svg" width="635" height="615" viewBox="0 0 635 615" fill="none" transform="scale(-1,1)">
      <g filter="url(#filter0_f_696_15483)">
        <path d="M473.534 215.257C472.846 179.353 498.897 157.064 512.009 150.407C508.283 163.935 500.402 193.783 498.682 204.95C496.533 218.908 506.85 271.303 514.158 311.03C521.466 350.756 481.057 412.6 397.659 445.669C330.941 472.125 230.434 464.566 188.52 457.48C170.321 446.671 138.051 426.558 154.559 432.57C175.194 440.086 293.842 415.606 331.457 403.796C369.072 391.985 450.105 335.295 459.563 316.183C469.02 297.072 474.394 260.137 473.534 215.257Z" fill="url(#paint0_linear_696_15483)" fill-opacity="0.3"/>
      </g>
      <defs>
        <filter id="filter0_f_696_15483" x="0" y="0.406738" width="665" height="613.798" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix"/>
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
          <feGaussianBlur stdDeviation="75" result="effect1_foregroundBlur_696_15483"/>
        </filter>
        <linearGradient id="paint0_linear_696_15483" x1="515" y1="150.407" x2="128.511" y2="179.588" gradientUnits="userSpaceOnUse">
          <stop stop-color="#1D91FC"/>
          <stop offset="1" stop-color="#0075FF"/>
        </linearGradient>
      </defs>
    </svg>
    <svg xmlns="http://www.w3.org/2000/svg" width="517" height="472" viewBox="0 0 517 472" fill="none" transform="scale(-1,1)">
      <g style="mix-blend-mode:hard-light" filter="url(#filter0_f_696_15484)">
        <path d="M380.723 156.461C380.127 125.412 402.681 106.135 414.032 100.379C410.806 112.078 403.983 137.891 402.495 147.548C400.634 159.618 409.566 204.93 415.893 239.286C422.219 273.641 387.236 327.124 315.036 355.722C257.276 378.601 170.263 372.064 133.977 365.936C118.222 356.589 90.2848 339.195 104.576 344.394C122.44 350.894 225.158 329.724 257.722 319.51C290.287 309.296 360.44 260.27 368.627 243.742C376.815 227.215 381.467 195.274 380.723 156.461Z" fill="url(#paint0_linear_696_15484)" fill-opacity="0.3"/>
      </g>
      <defs>
        <filter id="filter0_f_696_15484" x="0.628906" y="0.378662" width="515.992" height="471.373" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix"/>
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
          <feGaussianBlur stdDeviation="50" result="effect1_foregroundBlur_696_15484"/>
        </filter>
        <linearGradient id="paint0_linear_696_15484" x1="258.625" y1="100.379" x2="258.625" y2="371.751" gradientUnits="userSpaceOnUse">
          <stop stop-color="#9F6FEF"/>
          <stop offset="1" stop-color="#8759D3"/>
        </linearGradient>
      </defs>
    </svg>
  </div>
</div>
<div class="header-container">
    <div class="header-content">
        <div class="logo-wrapper" routerLink="/" (click)="scroll(Header.Hero)">
            <img class="logo" src="assets/draftzy_icon.svg">
            <span class="header-title">Draftzy</span>
        </div>
        <div *ngIf="screenWidth >= 1280" class="menu">
            <div class="menu-tab" (click)="scroll(Header.TokenSale)">
                <span class="page" routerLink="/tokensale" routerLinkActive="page--active">Token sale</span>
                <div class="link" routerLink="/tokensale" routerLinkActive="link--active"></div>
            </div>
            <div class="menu-tab" (click)="scroll(Header.AboutUs)">
                <span class="page" routerLink="/aboutus" routerLinkActive="page--active">About</span>
                <div class="link" routerLink="/aboutus" routerLinkActive="link--active"></div>
            </div>
            <!-- <div class="menu-tab" (click)="scroll(Header.Points)">
                <span class="page" routerLink="/points" routerLinkActive="page--active">Points</span>
                <div class="link" routerLink="/points" routerLinkActive="link--active"></div>
            </div>
            <div class="menu-tab" (click)="scroll(Header.Leagues)">
                <span class="page" routerLink="/leagues" routerLinkActive="page--active">Leagues</span>
                <div class="link" routerLink="/leagues" routerLinkActive="link--active"></div>
            </div>
            <div class="menu-tab" (click)="scroll(Header.Matches)">
                <span class="page" routerLink="/matches" routerLinkActive="page--active">Matches</span>
                <div class="link" routerLink="/matches" routerLinkActive="link--active"></div>
            </div>
            <div class="menu-tab" (click)="scroll(Header.Teams)">
                <span class="page" routerLink="/teams" routerLinkActive="page--active">Teams</span>
                <div class="link" routerLink="/teams" routerLinkActive="link--active"></div>
            </div> -->
            <div class="menu-tab" (click)="scroll(Header.Tokenomics)">
                <span class="page" routerLink="/tokenomics" routerLinkActive="page--active">Tokenomics</span>
                <div class="link" routerLink="/tokenomics" routerLinkActive="link--active"></div>
            </div>
            <div class="menu-tab" (click)="scroll(Header.Roadmap)">
                <span class="page" routerLink="/roadmap" routerLinkActive="page--active">Roadmap</span>
                <div class="link" routerLink="/roadmap" routerLinkActive="link--active"></div>
            </div>
            <div class="menu-tab" (click)="scroll(Header.Socials)">
                <span class="page" routerLink="/socials" routerLinkActive="page--active">Socials</span>
                <div class="link" routerLink="/socials" routerLinkActive="link--active"></div>
            </div>
        </div>
        <div class="button-container">
            <button class="button button--secondary_accent btn-login" (click)="goToDraftzy()">
                <span>Log in</span>
            </button>
            <button class="button button--accent btn-signup" (click)="goToDraftzyRegister()">
                <span>Sign up</span>
            </button>

            <div *ngIf="screenWidth < 1280" class="cross menu--1">
                <label id="burger-label" (click)="setHamburgerStatus(!hamburgerOpen)">
                    <input type="checkbox">
                    <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="50" cy="50" r="30" />
                        <path class="line--1" d="M0 40h62c13 0 6 28-4 18L35 35" />
                        <path class="line--2" d="M0 50h70" />
                        <path class="line--3" d="M0 60h62c13 0 6-28-4-18L35 65" />
                    </svg>
                </label>
            </div>
        </div>
    </div>

    <div *ngIf="hamburgerOpen" class="hamburger-menu" [@inOutAnimation]>
        <div class="overlay-blur"></div>
        <div [@inOutAnimation] class="menu">
            <div class="menu-tab" (click)="scroll(Header.TokenSale)">
                <span class="page" [@appearAnimation] routerLink="/tokensale" routerLinkActive="page--active">Token sale</span>
            </div>
            <div class="menu-tab" (click)="scroll(Header.AboutUs)">
                <span class="page" [@appearAnimation] routerLink="/aboutus" routerLinkActive="page--active">About</span>
            </div>
            <!-- <div class="menu-tab" (click)="scroll(Header.Points)">
                <span class="page" [@appearAnimation] routerLink="/points" routerLinkActive="page--active">Points</span>
            </div>
            <div class="menu-tab" (click)="scroll(Header.Leagues)">
                <span class="page" [@appearAnimation] routerLink="/leagues" routerLinkActive="page--active">Leagues</span>
            </div>
            <div class="menu-tab" (click)="scroll(Header.Matches)">
                <span class="page" [@appearAnimation] routerLink="/matches" routerLinkActive="page--active">Matches</span>
            </div>
            <div class="menu-tab" (click)="scroll(Header.Teams)">
                <span class="page" [@appearAnimation] routerLink="/teams" routerLinkActive="page--active">Teams</span>
            </div> -->
            <div class="menu-tab" (click)="scroll(Header.Tokenomics)">
                <span class="page" [@appearAnimation] routerLink="/tokenomics" routerLinkActive="page--active">Tokenomics</span>
            </div>
            <div class="menu-tab" (click)="scroll(Header.Roadmap)">
                <span class="page" [@appearAnimation] routerLink="/roadmap" routerLinkActive="page--active">Roadmap</span>
            </div>
            <div class="menu-tab" (click)="scroll(Header.Socials)">
                <span class="page" [@appearAnimation] routerLink="/socials" routerLinkActive="page--active">Socials</span>
            </div>
            
        </div>
    </div>
</div>